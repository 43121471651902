<template>
  <footer class="main-footer sticky footer-type-1">
    <div class="footer-inner">
      <!-- 版权信息 -->
      <div class="footer-text">
        &copy; 欢迎关注公众号
        <a href="#"><strong>「小白debug」</strong></a>
        一起在知识的海洋里
        <a href="https://xiaobaidebug.top" target="_blank"
          ><strong>呛水吧！</strong></a
        >
      </div>

      <div class="go-up">
        <a href="#" rel="go-top"><i class="fa-angle-up"></i></a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style></style>
